import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { isBrowser } from 'utils/browser';
import { SEO } from 'components/Seo';

export default function Logout() {
  if (!isBrowser) return null;
  const { loginWithRedirect } = useAuth0();
  loginWithRedirect({ screen_hint: 'singup' });
  return null;
}

export const Head = () => {
  return <SEO title="Tutadoo | Register" />;
};
